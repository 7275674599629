<template>
  <div>
    <div v-if="showPanels">
      <v-data-table
        :headers="headers"
        :items="delinquents"
        :page.sync="page"
        :items-per-page="delinquentsPerPage"
        :server-items-length="totalDelinquents"
        hide-default-footer
        @page-count="pageCount = $event"
        sort-by="name"
        class="elevation-1 ml-4"
        :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Delinquents</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              label="Search by Name"
              color="grey"
              hide-details
              style="max-width: 300px;"
              v-model="names"
              dense
            >
              <template v-slot:append-outer>
                <v-btn
                  class="mt-n2"
                  elevation="1"
                  fab
                  small
                  @click="searchName"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="switchPanel(item)">
            Assessment
          </v-icon>
        </template>
      </v-data-table>
      <div class="pt-2 px-12">
        <v-row>
          <v-col cols="12" sm="2">
            <v-select
              :items="selectDelinquentsPerPage"
              v-model="delinquentsPerPage"
              label="Delinquents per page"
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="8">
            <v-pagination
              v-model="page"
              :length="pageCount"
              circle
              total-visible="7"
              color="blue"
            ></v-pagination>
          </v-col>
          <v-col cols="12" sm="2"> {{ totalDelinquents }} Records </v-col>
        </v-row>
      </div>
    </div>
    <div v-if="!showPanels">
      <v-expansion-panels class="ml-4" v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header
            >Delinquent Details</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" sm="12">
                <p class="mb-n4 font-weight-thin">Personal Identification</p>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="NID"
                  type="text"
                  outlined
                  v-model="editedDelinquent.nid"
                  filled
                  color="grey"
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Passport"
                  type="text"
                  outlined
                  v-model="editedDelinquent.passport"
                  color="grey"
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Phone"
                  type="text"
                  outlined
                  v-model="editedDelinquent.guardianPhone"
                  color="grey"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="First Name"
                  type="text"
                  outlined
                  v-model="editedDelinquent.firstName"
                  color="grey"
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Last Name"
                  type="text"
                  outlined
                  v-model="editedDelinquent.lastName"
                  color="grey"
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Gender"
                  item-text="name"
                  outlined
                  v-model="editedDelinquent.gender"
                  return-object
                  class="mt-n6"
                  color="grey"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Marital Status"
                  item-text="name"
                  outlined
                  v-model="editedDelinquent.maritalStatus"
                  class="mt-n6"
                  color="grey"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Father Name"
                  v-model="editedDelinquent.fatherName"
                  type="text"
                  outlined
                  color="grey"
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Mother Name"
                  type="text"
                  outlined
                  v-model="editedDelinquent.motherName"
                  color="grey"
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="12">
                <p class="mt-n6 font-weight-thin">Place and Date of Birth</p>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="birthProvinces"
                  item-text="name"
                  item-value="id"
                  v-model="editedDelinquent.placeOfBirth.province.id"
                  dense
                  color="grey"
                  label="Province"
                  outlined
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="birthDistricts"
                  item-text="name"
                  item-value="id"
                  v-model="editedDelinquent.placeOfBirth.district.id"
                  dense
                  color="grey"
                  label="District"
                  outlined
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="birthSectors"
                  item-text="name"
                  item-value="id"
                  v-model="editedDelinquent.placeOfBirth.sector.id"
                  dense
                  color="grey"
                  label="Sector"
                  outlined
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="birthCells"
                  item-text="name"
                  item-value="id"
                  v-model="editedDelinquent.placeOfBirth.cell.id"
                  dense
                  color="grey"
                  label="Cell"
                  outlined
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="birthVillages"
                  item-text="name"
                  item-value="id"
                  v-model="editedDelinquent.placeOfBirth.village.id"
                  dense
                  color="grey"
                  label="Village"
                  outlined
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="editedDelinquent.latestScreening.meetingDate"
                  label="Meeting Date"
                  outlined
                  filled
                  color="grey"
                  class="mt-n6"
                  dense
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <p class="mt-n6 font-weight-thin">Place of Residence</p>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="residenceProvinces"
                  item-text="name"
                  item-value="id"
                  v-model="
                    editedDelinquent.apprehension.delinquentResidence.province
                      .id
                  "
                  dense
                  color="grey"
                  label="Province"
                  outlined
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="residenceDistricts"
                  item-text="name"
                  item-value="id"
                  v-model="
                    editedDelinquent.apprehension.delinquentResidence.district
                      .id
                  "
                  dense
                  color="grey"
                  label="District"
                  outlined
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="residenceSectors"
                  item-text="name"
                  item-value="id"
                  v-model="
                    editedDelinquent.apprehension.delinquentResidence.sector.id
                  "
                  dense
                  color="grey"
                  label="Sector"
                  outlined
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="residenceCells"
                  item-text="name"
                  item-value="id"
                  v-model="
                    editedDelinquent.apprehension.delinquentResidence.cell.id
                  "
                  dense
                  color="grey"
                  label="Cell"
                  outlined
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="residenceVillages"
                  item-text="name"
                  item-value="id"
                  v-model="
                    editedDelinquent.apprehension.delinquentResidence.village.id
                  "
                  dense
                  color="grey"
                  label="Village"
                  outlined
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Next Of Kin Name"
                  type="text"
                  v-model="editedDelinquent.guardian"
                  outlined
                  name="Next Of Kin Name"
                  color="grey"
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Next Of Kin Phone"
                  type="text"
                  outlined
                  v-model="editedDelinquent.guardianPhone"
                  color="grey"
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Next Of Kin Email"
                  type="text"
                  outlined
                  v-model="editedDelinquent.guardianEmail"
                  color="grey"
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            >Apprehension Details</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" sm="12">
                <p class="font-weight-thin">
                  Place, Date and other details of Apprehension
                </p>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="apprehensionProvinces"
                  item-text="name"
                  item-value="id"
                  v-model="
                    editedDelinquent.apprehension.apprehensionPlace.province.id
                  "
                  dense
                  color="grey"
                  label="Province"
                  outlined
                  name="Apprehension Province"
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="apprehensionDistricts"
                  item-text="name"
                  item-value="id"
                  v-model="
                    editedDelinquent.apprehension.apprehensionPlace.district.id
                  "
                  dense
                  color="grey"
                  label="District"
                  outlined
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="apprehensionSectors"
                  item-text="name"
                  item-value="id"
                  v-model="
                    editedDelinquent.apprehension.apprehensionPlace.sector.id
                  "
                  dense
                  color="grey"
                  label="Sector"
                  outlined
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="apprehensionCells"
                  item-text="name"
                  item-value="id"
                  v-model="
                    editedDelinquent.apprehension.apprehensionPlace.cell.id
                  "
                  dense
                  color="grey"
                  label="Cell"
                  outlined
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="apprehensionVillages"
                  item-text="name"
                  item-value="id"
                  v-model="
                    editedDelinquent.apprehension.apprehensionPlace.village.id
                  "
                  dense
                  color="grey"
                  label="Village"
                  outlined
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="editedDelinquent.latestScreening.meetingDate"
                  label="Meeting Date"
                  outlined
                  filled
                  color="grey"
                  class="mt-n6"
                  dense
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  dense
                  label="How he/she was apprehended and act he/she was doing"
                  type="text"
                  outlined
                  v-model="editedDelinquent.apprehension.apprehensionWay"
                  color="grey"
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Deviant Acts"
                  type="text"
                  outlined
                  v-model="editedDelinquent.apprehension.deviantActs"
                  name="Last Name"
                  color="grey"
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Apprehension Items"
                  type="text"
                  outlined
                  v-model="editedDelinquent.apprehension.items"
                  color="grey"
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Identification Documents"
                  type="text"
                  outlined
                  v-model="editedDelinquent.apprehension.documentTypes"
                  name="Last Name"
                  color="grey"
                  class="mt-n6"
                  disabled
                  filled
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Screening Details</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Mother Vital Status"
                  outlined
                  v-model="editedDelinquent.motherVitalStatus"
                  color="grey"
                  filled
                  class="mt-n6"
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Father Vital Status"
                  outlined
                  v-model="editedDelinquent.fatherVitalStatus"
                  color="grey"
                  filled
                  class="mt-n6"
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Homeless Status"
                  v-model="editedDelinquent.homeless"
                  outlined
                  color="grey"
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Specific Disease"
                  type="text"
                  outlined
                  v-model="editedDelinquent.specificDisease"
                  color="grey"
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Education"
                  outlined
                  v-model="editedDelinquent.educationLevel"
                  color="grey"
                  filled
                  class="mt-n6"
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="editedDelinquent.latestScreening.meetingDate"
                  label="Meeting Date"
                  outlined
                  filled
                  color="grey"
                  class="mt-n6"
                  dense
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Decision taken"
                  outlined
                  v-model="editedDelinquent.latestScreening.decision"
                  color="grey"
                  filled
                  class="mt-n6"
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Reason for decision taken"
                  type="text"
                  outlined
                  v-model="editedDelinquent.latestScreening.decisionReason"
                  color="grey"
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Apprehendee opinion"
                  type="text"
                  outlined
                  v-model="
                    editedDelinquent.latestScreening.delinquentPointOfView
                  "
                  color="grey"
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="Parent/Next Of Kin opinion"
                  type="text"
                  outlined
                  v-model="editedDelinquent.latestScreening.guardianPointOfView"
                  color="grey"
                  class="mt-n6"
                  filled
                  disabled
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            >Assessment Details</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <div>
              <v-data-table
                :headers="headers1"
                :items="assessments"
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-dialog v-model="dialog" max-width="1200px">
                      <template v-slot:activator="{ on }">
                        <v-spacer></v-spacer>
                        <v-btn
                          color="green"
                          dark
                          class="mb-2"
                          v-on="on"
                          small
                          right
                          >Register Assessment</v-btn
                        >
                      </template>
                      <v-card>
                        <validation-observer ref="form">
                          <form @submit.prevent="onSubmit">
                            <v-card-title>
                              <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="4">
                                    <validation-provider
                                      v-slot="{ errors }"
                                      rules="required"
                                      name="Assessment Type"
                                    >
                                      <v-select
                                        dense
                                        :items="assessmentTypes"
                                        label="Assessment Type"
                                        outlined
                                        v-model="editedAssessment.type"
                                        color="grey"
                                        :error-messages="errors"
                                        filled
                                        class="mt-n6"
                                      />
                                    </validation-provider>
                                  </v-col>
                                  <v-col cols="12" sm="4">
                                    <v-menu
                                      ref="assessmentDateMenu"
                                      v-model="assessmentDateMenu"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="290px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <validation-provider
                                          v-slot="{ errors }"
                                          rules="required"
                                          name="Assessment Date"
                                        >
                                          <v-text-field
                                            v-model="formattedAssessmentDate"
                                            label="Assessment Date"
                                            outlined
                                            filled
                                            name="Assessment Date"
                                            :error-messages="errors"
                                            color="grey"
                                            class="mt-n6"
                                            readonly
                                            dense
                                            v-on="on"
                                          ></v-text-field>
                                        </validation-provider>
                                      </template>
                                      <v-date-picker
                                        ref="picker"
                                        v-model="assessmentDate"
                                        :max="
                                          new Date().toISOString().substr(0, 10)
                                        "
                                        min="1900-01-01"
                                        @change="saveAssessmentDate"
                                        no-title
                                      ></v-date-picker>
                                    </v-menu>
                                  </v-col>
                                  <v-col cols="12" sm="4">
                                    <v-text-field
                                      dense
                                      label="Approach"
                                      v-model="editedAssessment.approach"
                                      color="grey"
                                      class="mt-n6"
                                      filled
                                      outlined
                                    />
                                  </v-col>
                                  <v-col cols="12" sm="4">
                                    <v-text-field
                                      dense
                                      label="Used Tools"
                                      outlined
                                      v-model="editedAssessment.tool"
                                      color="grey"
                                      filled
                                      class="mt-n6"
                                    />
                                  </v-col>
                                  <v-col cols="12" sm="4">
                                    <v-text-field
                                      dense
                                      label="Attitude"
                                      outlined
                                      v-model="editedAssessment.clientAttitude"
                                      color="grey"
                                      filled
                                      class="mt-n6"
                                    />
                                  </v-col>
                                  <v-col cols="12" sm="4">
                                    <v-text-field
                                      dense
                                      label="Outcome"
                                      outlined
                                      v-model="editedAssessment.outcome"
                                      color="grey"
                                      filled
                                      class="mt-n6"
                                    />
                                  </v-col>
                                  <v-col cols="12" sm="12">
                                    <validation-provider
                                      v-slot="{ errors }"
                                      rules="required"
                                      name="Observation"
                                    >
                                      <v-text-field
                                        v-model="editedAssessment.observation"
                                        label="Observation"
                                        outlined
                                        filled
                                        :error-messages="errors"
                                        color="grey"
                                        class="mt-n6"
                                        dense
                                      ></v-text-field>
                                    </validation-provider>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn
                                color="green"
                                dark
                                class="ml-12 mt-n10 mb-4"
                                type="submit"
                                >Save</v-btn
                              >
                              <v-btn class="mt-n10 mb-4" @click="close"
                                >Cancel</v-btn
                              >
                            </v-card-actions>
                          </form>
                        </validation-observer>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="editAssessment(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small class="mr-2" @click="assessmentDialog(item)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <v-btn @click="showPanels = true" class="ml-4 mt-4" icon v-if="!showPanels"
      ><v-icon>mdi-arrow-left</v-icon>Back</v-btn
    >
    <v-snackbar v-model="snackbar" top :color="color" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" icon v-bind="attrs" @click="setSnackbar(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" hide-overlay max-width="500px">
      <v-card tile outlined>
        <v-card-text>
          <p>Are you sure you want to delete this assesment ?</p>
          <v-btn link small color="blue" @click="deleteAssessment()">Yes</v-btn>
          <v-btn link small color="blue" @click="deleteDialog = false"
            >No</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import { WebCam } from 'vue-web-cam'
import { mapState, mapMutations, mapActions } from 'vuex'
import Delinquent from '@/models/Delinquent'
import Assessment from '@/models/Assessment'
export default {
  data () {
    return {
      dialog: false,
      privileges: null,
      showEdit: false,
      showDelete: false,
      showCreate: false,
      deleteDialog: false,
      showPanels: false,
      names: null,
      headers: [
        {
          text: 'Names',
          align: 'start',
          sortable: false,
          value: 'names'
        },
        { text: 'Date of Birth', value: 'delinquent.dateOfBirth' },
        { text: 'Apprehension Reason', value: 'apprehension.deviantActs' },
        { text: 'Arrival Date', value: 'arrivalDate' },
        { text: 'Stay Period', value: 'apprehension.elapsedDays' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      headers1: [
        {
          text: 'No',
          align: 'start',
          sortable: false,
          value: 'number'
        },
        { text: 'Assessment Type', value: 'type' },
        { text: 'Date', value: 'date' },
        { text: 'Approach', value: 'approach' },
        { text: 'Tools', value: 'tool' },
        { text: 'Attitude', value: 'clientAttitude' },
        { text: 'Outcome', value: 'outcome' },
        { text: 'Observation', value: 'observation' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      delinquents: [],
      assessments: [],
      selectDelinquentsPerPage: [2, 5, 10, 15, 20, 25, 30, 50, 100, 2000],
      page: 1,
      pageCount: 0,
      delinquentsPerPage: 20,
      totalDelinquents: 0,
      singleSelect: false,
      selected: [],
      assessmentTypes: [],
      birthProvinces: [],
      apprehensionProvinces: [],
      residenceProvinces: [],
      birthDistricts: [],
      apprehensionDistricts: [],
      residenceDistricts: [],
      birthSectors: [],
      apprehensionSectors: [],
      residenceSectors: [],
      birthCells: [],
      apprehensionCells: [],
      residenceCells: [],
      birthVillages: [],
      apprehensionVillages: [],
      residenceVillages: [],
      editedAssessmentIndex: -1,
      editedDelinquent: new Delinquent(),
      editedAssessment: new Assessment(),
      defaultAssessmentItem: new Assessment(),
      delinquentItem: null,
      transferId: null,
      assessmentDate: null,
      retrievedAssessmentDate: null,
      assessmentDateMenu: false,
      errors: null,
      panel: 3
    }
  },

  computed: {
    formTitle () {
      return this.editedAssessmentIndex === -1
        ? 'Register Assessment'
        : 'Edit Assessment'
    },
    formattedAssessmentDate () {
      return this.assessmentDate
        ? this.$moment(this.assessmentDate).format('DD-MMM-YYYY')
        : this.retrievedAssessmentDate
          ? this.$moment(this.retrievedAssessmentDate).format('DD-MMM-YYYY')
          : ''
    },
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'currentUser',
      'currentCenter',
      'search',
      'storeProvinces',
      'storeProvincesError',
      'deviantActs',
      'identificationDocuments',
      'apprehensionItems'
    ])
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    page: {
      handler () {
        this.loadAlldelinquents()
      }
    },
    delinquentsPerPage: {
      handler () {
        this.page = 1
        this.loadAlldelinquents()
      }
    }
  },
  mounted () {
    this.loadAlldelinquents()
    this.loadAssessmentTypes()
    this.loadBirthProvinces()
  },
  created () {
    this.showPanels = true
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
  },
  destroyed () {
    this.setSnackbar(false)
  },

  methods: {
    ...mapActions('store', ['loadStoreProvinces']),
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT',
      setDelinquentItem: 'SET_DELINQUENT_ITEM'
    }),
    loadAlldelinquents () {
      const pageNumber = this.page - 1
      this.$delinquentService
        .fetchAllTransfers(
          'deviant_acts',
          false,
          null,
          null,
          'REHABILITATION',
          pageNumber,
          this.delinquentsPerPage
        )
        .then(response => {
          if (!response.status) {
            this.delinquents = response.content
            this.totalDelinquents = response.totalElements
            this.delinquents.forEach(element => {
              element.names =
                element.delinquent.firstName +
                ' ' +
                element.delinquent.lastName
              element.delinquent.dateOfBirth = this.$moment(
                element.delinquent.dateOfBirth
              ).format('DD-MMM-YYYY')
              element.arrivalDate = this.$moment(element.arrivalDate).format(
                'DD-MMM-YYYY'
              )
            })
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Delinquents: ' + response.message)
          }
        })
    },
    loadAssessments () {
      this.$delinquentService
        .fetchDelinquentAssessments(this.transferId)
        .then(response => {
          if (!response.status) {
            this.assessments = response
            this.assessments.forEach(element => {
              element.date = this.$moment(element.date).format('DD-MMM-YYYY')
              element.number = this.assessments.indexOf(element) + 1
            })
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Assessments: ' + response.message)
          }
        })
    },
    loadAssessmentTypes () {
      this.$delinquentService.fetchAssessmentTypes().then(response => {
        if (!response.status) {
          this.assessmentTypes = response
        } else {
          // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
          this.setSnackbar(true)
          this.setColor('red')
          this.setText('Read Assessments: ' + response.message)
        }
      })
    },
    loadBirthProvinces () {
      if (this.storeProvinces.length > 0) {
        this.birthProvinces = this.storeProvinces
        this.apprehensionProvinces = this.storeProvinces
        this.residenceProvinces = this.storeProvinces
      } else this.reloadStoreProvinces()
    },
    async reloadStoreProvinces () {
      await this.loadStoreProvinces
      if (this.storeProvinces.length > 0) {
        this.birthProvinces = this.storeProvinces
        this.apprehensionProvinces = this.storeProvinces
        this.residenceProvinces = this.storeProvinces
      } else {
        this.setSnackbar(true)
        this.setColor('red')
        this.setText('Provinces: ' + this.storeProvincesError)
      }
    },
    editAssessment (item) {
      this.editedAssessmentIndex = this.assessments.indexOf(item)
      this.$delinquentService.fetchAssessmentById(item.id).then(response => {
        if (response.id) {
          this.editedAssessment = response
          this.retrievedAssessmentDate = response.date
        } else {
          // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
      this.dialog = true
    },
    assessmentDialog (item) {
      this.assessmentIndex = this.assessments.indexOf(item)
      this.assessmentItem = item
      this.deleteDialog = true
    },
    loadProfile (item) {
      this.setDelinquentItem(item)
      this.$router.push('/delinquent-profile')
    },
    switchPanel (item) {
      this.delinquentIndex = this.delinquents.indexOf(item)
      this.delinquentItem = item
      this.$delinquentService
        .loadDelinquentIdentification(
          item.delinquent.id,
          'latest_apprehension,general_screening,medical_screening',
          'REHABILITATION'
        )
        .then(response => {
          if (!response.status) {
            this.showPanels = !this.showPanels
            this.editedDelinquent = response
            this.transferId = response.latestTransfer.id
            this.fetchBirthSiblings()
            this.fetchApprehensionSiblings()
            this.fetchResidenceSiblings()
            this.loadAssessments()
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },

    deleteAssessment () {
      this.deleteDialog = false
      this.$delinquentService
        .deleteAssessment(this.assessmentItem.id)
        .then(response => {
          if (response.status === 'success') {
            this.assessments.splice(this.assessmentIndex, 1)
            this.assessments.forEach(element => {
              element.number = this.assessments.indexOf(element) + 1
            })
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    searchName () {
      if (this.names) {
        this.$delinquentService
          .searchName('deviant_acts', 'REHABILITATION', this.names)
          .then(response => {
            if (!response.status) {
              this.delinquents = response.content
              this.delinquents.forEach(element => {
                element.names =
                  element.delinquent.firstName +
                  ' ' +
                  element.delinquent.lastName
                element.delinquent.dateOfBirth = this.$moment(
                  element.delinquent.dateOfBirth
                ).format('DD-MMM-YYYY')
                element.arrivalDate = this.$moment(element.arrivalDate).format(
                  'DD-MMM-YYYY'
                )
              })
            } else {
              // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
              this.setSnackbar(true)
              this.setColor('red')
              this.setText(response.message)
            }
          })
      } else {
        this.loadAlldelinquents()
      }
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedAssessment = Object.assign({}, this.defaultAssessmentItem)
        this.editedAssessmentIndex = -1
      }, 300)
    },
    onSubmit () {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }
        if (this.editedAssessmentIndex > -1) {
          this.updateAssessment()
        } else this.createAssessment()
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      })
    },
    updateAssessment () {
      const deletedAssessmentArray = this.assessments.splice(
        this.editedAssessmentIndex,
        1
      )
      this.editedAssessment.date = this.formattedAssessmentDate
      this.editedAssessment.transfer = {}
      this.editedAssessment.transfer.id = this.transferId
      this.$delinquentService
        .saveAssessment(this.editedAssessment)
        .then(response => {
          if (response.id) {
            this.assessments.splice(this.editedAssessmentIndex, 0, response)
            this.assessments.forEach(element => {
              element.number = this.assessments.indexOf(element) + 1
            })
            this.close()
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.assessments.splice(
              this.editedAssessmentIndex,
              0,
              deletedAssessmentArray[0]
            )
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    createAssessment () {
      this.editedAssessment.date = this.formattedAssessmentDate
      this.editedAssessment.transfer = {}
      this.editedAssessment.transfer.id = this.transferId
      this.$delinquentService
        .saveAssessment(this.editedAssessment)
        .then(response => {
          if (response.id) {
            this.assessments.push(response)
            this.assessments.forEach(element => {
              element.number = this.assessments.indexOf(element) + 1
            })
            this.close()
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    saveAssessmentDate (assessmentDate) {
      this.$refs.assessmentDateMenu.save(assessmentDate)
    },
    fetchBirthSiblings () {
      this.$locationService
        .fetchAllSiblings(this.editedDelinquent.placeOfBirth)
        .then(response => {
          if (response.status) {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Location: ' + response.message)
          } else {
            [
              this.birthDistricts,
              this.birthSectors,
              this.birthCells,
              this.birthVillages
            ] = response
          }
        })
    },
    fetchApprehensionSiblings () {
      this.$locationService
        .fetchAllSiblings(this.editedDelinquent.apprehension.apprehensionPlace)
        .then(response => {
          if (response.status) {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Location: ' + response.message)
          } else {
            [
              this.apprehensionDistricts,
              this.apprehensionSectors,
              this.apprehensionCells,
              this.apprehensionVillages
            ] = response
          }
        })
    },
    fetchResidenceSiblings () {
      this.$locationService
        .fetchAllSiblings(
          this.editedDelinquent.apprehension.delinquentResidence
        )
        .then(response => {
          if (response.status) {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Location: ' + response.message)
          } else {
            [
              this.residenceDistricts,
              this.residenceSectors,
              this.residenceCells,
              this.residenceVillages
            ] = response
          }
        })
    },
    checkPrivileges () {
      this.privileges.includes('CREATE_DELINQUENTS') ||
      this.privileges.includes('ALL_AUTHORITIES')
        ? (this.showCreate = true)
        : (this.showCreate = false)
      this.privileges.includes('UPDATE_DELINQUENTS') ||
      this.privileges.includes('ALL_AUTHORITIES')
        ? (this.showEdit = true)
        : (this.showEdit = false)
      this.privileges.includes('DELETE_DELINQUENTS') ||
      this.privileges.includes('ALL_AUTHORITIES')
        ? (this.showDelete = true)
        : (this.showDelete = false)
      this.privileges.includes('CREATE_ASSESSMENTS') ||
      this.privileges.includes('ALL_AUTHORITIES')
        ? (this.showCreate = true)
        : (this.showCreate = false)
      this.privileges.includes('UPDATE_ASSESSMENTS') ||
      this.privileges.includes('ALL_AUTHORITIES')
        ? (this.showEdit = true)
        : (this.showEdit = false)
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
