import Transfer from './Transfer'
class Assessment {
  constructor () {
    this.id = null
    this.type = null
    this.date = null
    this.approach = null
    this.tool = null
    this.clientAttitude = null
    this.outcome = null
    this.observation = null
    this.transfer = new Transfer()
  }

  toString () {
    return `Assessment{id=${this.id}, type='${this.type}', assessmentDate='${this.assessmentDate}',
    approach='${this.approach}', usedTools='${this.usedTools}', attitude='${this.attitude}',
    outcome='${this.outcome}', observation='${this.observation}', transfer='${this.transfer}'}`
  }
}

export default Assessment
